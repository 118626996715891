import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Ticketlessgate = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`チケットレスゲート | ${title}`}</title>
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>チケットレスゲートシステム</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>チケットレスゲートシステム</li>
        </div>
      </div>

      <div className="main-wrapper ticketless">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>チケットレスゲートシステム</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-numberrecognition.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              待望のITCチケットレスゲートレスシステムが登場。
              <br />
              駐車券をなくすことによりスムーズな入出庫が可能になりました。
            </h4>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  事前精算機を設置することでよりスムーズな出庫が可能となり、出口で渋滞にならない
                </li>
                <li>駐車券の券詰まりのトラブルが一切発生しない</li>
                <li>駐車券などの消耗品の費用が抑えられる</li>
                <li>
                  ナンバー解析システムにより、月極契約者の利用も簡単にー定期券の管理が必要がなくなりました
                </li>
                <li>
                  駐車場のロケーションに合わせ、多彩なオプションを選択可能
                  <br />
                  −　ゲートレス、キャッシュレス決済（電子マネー、バーコード、クレジット、スマートフォン）、サービス券に対応
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>基本構成イメージ</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <div className="left-block">
              <p>入口</p>

              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-ticketless-01.webp"
                />
                <img
                  className="ticketless-01"
                  src="/assets/images/common/service-ticketless-01.png"
                  alt=""
                  height="220"
                />
              </picture>
              <div className="notice">
                ※時刻表示機には、万が一ナンバー認識できない場合に備え、発券機能付きも選択可能
              </div>

              <p>出口</p>

              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-ticketless-02.webp"
                />
                <img
                  className="ticketless-02"
                  src="/assets/images/common/service-ticketless-02.png"
                  alt=""
                  height="220"
                />
              </picture>
              <div className="notice">
                ※精算機はオプションとして、キャッシュレス機能も選択可能
              </div>
            </div>

            <div className="right-block">
              <p>事前精算機</p>

              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-ticketless-jizen.webp"
                />
                <img
                  className="ticketless-jizen"
                  src="/assets/images/common/service-ticketless-jizen.png"
                  alt=""
                  height="268"
                />
              </picture>
              <div className="notice-summary">
                ※事前精算機はオプションとなります。事前精算機を設置する場合、入口ゲートは必要となります。
                <br />
                ※キャッシュレス機能も選択可能
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>事前精算機 標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <h5>一般仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td>H1548.2mm×W525mm×D458.8mm</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約90kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V　50/60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時 50W 動作時 80W（AC-100V）</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度　0℃～45℃　湿度　結露しないこと</td>
                </tr>
              </tbody>
            </table>

            <h5>機能</h5>

            <table>
              <tbody>
                <tr>
                  <th>使用可能金種</th>
                  <td className="multi">
                    紙 幣： 1000 円紙幣（新旧受入可能）
                    <br />硬 貨： 10 円・50 円・100 円・500 円
                  </td>
                </tr>
                <tr>
                  <th className="multi">つり銭保有枚数（循環式）</th>
                  <td className="multi">
                    10 円硬貨（約 92 枚）
                    <br />
                    50 円硬貨（約 85 枚）
                    <br />
                    100 円硬貨（約 80 枚）
                    <br />
                    500 円硬貨（約 70 枚）
                    <br />
                    サブチューブ（10 円：100 枚、100 円：87 枚 選択可能）
                    <br />
                    補助機付きコインメック （10 円：155 枚、100 円：140 枚
                    選択可能）
                    <br />
                    オーバーフロー保管金庫 <br />
                    硬貨（混合）：約 2,000 枚（100 円硬貨換算）
                    <br />
                    紙幣収納枚数：800 枚ストッカー（新紙幣にて）およそ 450 枚±50
                    枚
                  </td>
                </tr>
                <tr>
                  <th className="multi">その他決済方法（オプション）</th>
                  <td className="multi">
                    クレジット決済
                    <br />
                    FeliCa などでの決済
                    <br />
                    電子マネーでの決済
                    <br />
                  </td>
                </tr>
                <tr>
                  <th>領収書発行</th>
                  <td className="multi">
                    約 800 枚印字可能
                    <br />
                    ※領収書の駐車場名及び会社名は出荷時設定
                  </td>
                </tr>
                <tr>
                  <th>サービス券</th>
                  <td className="multi">
                    {`< オフラインサービス券 >`}
                    <br />
                    店舗等にサービス券発券機を置いて頂き、店舗にて発券。
                    または、事前に作成したサービス券。
                    <br />
                    <br />
                    {`< 領収書について発行されるサービス券（任意設定）>`}
                    <br />
                    領収書ボタンを押した時に、領収書とともにサービス券を発券。
                    駐車料金が一定金額（任意設定）以上の場合に発券されます
                  </td>
                </tr>
              </tbody>
            </table>

            <h5>事前精算機の操作画面</h5>

            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-ticketless-03.webp"
                  />
                  <img
                    className="ticketless-03"
                    src="/assets/images/common/service-ticketless-03.png"
                    alt=""
                    height="183"
                  />
                </picture>
                <p>1）ナンバーを入力します</p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-ticketless-04.webp"
                  />
                  <img
                    className="ticketless-04"
                    src="/assets/images/common/service-ticketless-04.png"
                    alt=""
                    height="183"
                  />
                </picture>
                <p>2）該当車種の入庫写真が表示されます。確認します。</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>発券機 標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <h5>一般仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法 </th>
                  <td>H1300mm×W432.4mm×D500mm</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約60kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V　50/60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時 30W 稼動時 80W（AC-100V）</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度　0℃～45℃　湿度　結露しないこと</td>
                </tr>
              </tbody>
            </table>

            <h5>機能</h5>

            <table>
              <tbody>
                <tr>
                  <th>発券枚数</th>
                  <td>ロール紙1 本 約3000 枚</td>
                </tr>
                <tr>
                  <th>ロール紙保有本数</th>
                  <td> 1 本</td>
                </tr>
                <tr>
                  <th>定期券パス券読取機構</th>
                  <td>RFID 方式 有り</td>
                </tr>
                <tr>
                  <th>音声ガイダンス</th>
                  <td>有り</td>
                </tr>
                <tr>
                  <th>車両検知</th>
                  <td>ループコイル方式・光センサー・超音波</td>
                </tr>
                <tr>
                  <th className="multi">未入庫後の自動駐車券回収機能</th>
                  <td>有り</td>
                </tr>
                <tr>
                  <th className="multi">遠隔操作で領収書の発行機能</th>
                  <td>有り</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>精算機 標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <h5>一般仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法 </th>
                  <td> H1372mm×W567mm×D380mm（突起部含む）</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約120kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>AC100V　50/60Hz</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時 50W　動作時80W（AC100V）</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度　0℃～45℃　湿度　結露しないこと</td>
                </tr>
                <tr>
                  <th>対応貨幣</th>
                  <td> 10円・50円・100円・500円硬貨　1000円紙幣</td>
                </tr>
                <tr>
                  <th>釣銭払出</th>
                  <td>循環式</td>
                </tr>
                <tr>
                  <th>領収書発行</th>
                  <td className="multi">
                    約 800 枚印字可能
                    <br />
                    ※領収書の駐車場名及び会社名はリモート設定可能
                  </td>
                </tr>
                <tr>
                  <th>料金設定</th>
                  <td>
                    平日・土・日・祝日　それぞれの曜日に対し各3段階設定可能
                  </td>
                </tr>
                <tr>
                  <th>表示部</th>
                  <td>メッセージ　全角16文字　料金　6桁　0～999,999</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>アルコール検知機（オプション）</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Ticketlessgate
